.chip_style {
     border-radius: 4px !important;
     height: 24px !important;
     min-width: 30px !important;
     max-width: 200px !important;
     font-weight: 100 !important;
     background-color: $app_default_primary_color !important;
     border: 1px solid $app_default_primary_color !important;
     font-weight: 400 !important;
     font-size: 12px !important;
     line-height: 14px !important;
     letter-spacing: 0.4px !important;
     color: #FFFFFF !important;
}

.chip_delete_icon {
     height: 14px !important;
     color: #FFFFFF !important;
}

.menu_item_selectAll_deselectAll_label {
     padding-left: 25px;
     color: $app_default_primary_color;
     cursor: pointer;
}

.list_item_label {
     font-style: normal;
     font-weight: 400;
     font-size: 14px;
     line-height: 17px;
     letter-spacing: 0.25px;
     color: #222829;
}

.list_item_checkbox {
     border-radius: 4px;
     box-sizing: border-box;
     border: 1px solid #9DA3A3;
}

.chip_box {
     display: flex;
     flex-wrap: nowrap;
     width: inherit;
     height: inherit;
     gap: 4px;
     font-size: 0.75rem;
     padding: 0;
     margin: 0;
}

.chip_dots {
     padding-top: 4px;
     font-weight: bold;
}

.form_select {
     padding-top: 15px !important;
     width: inherit !important;
}

.total_chip_count {
     padding-top: 2px;
}

.menu_item {
     background: #FFFFFF !important;
}

.single_select_label {
     font-style: normal;
     font-weight: 400;
     font-size: 14px;
     line-height: 17px;
     letter-spacing: 0.25px;
     color: #4B5152;
}

.select_input_label {
     padding: 11px 0 0 18px !important;
     color: #000000DE !important;
}
