
.button_icon_transparent {
    position: relative;
    width: 40px !important;
    height: 40px !important;
    background-color: transparent;
    cursor: pointer;
    color: #2196F3 !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    letter-spacing: 0.5px !important;
    text-transform: none !important;
    align-items: flex-end !important;
}

.button_icon_search {
    position: relative;
    width: 40px !important;
    height: 40px !important;
    background: url("/vendors/wovo/icons/common/icon_filter.svg") no-repeat center center;
    background-size: 50% 50%;
}

.button_icon_settings {
    position: relative;
    width: 40px !important;
    height: 40px !important;
    background: url("/vendors/wovo/icons/common/icon_settings_circle.svg") no-repeat center center;
    background-size: 75% 75%;
}

.button_icon_search:hover {
    background: url("/vendors/wovo/icons/common/icon_filter_active.svg") no-repeat center center !important;
    background-size: 50% 50%;
}

.button_icon_back {
    position: relative;
    width: 40px !important;
    height: 40px !important;
    background: url("/vendors/wovo/icons/common/icon_navigation_back.svg") no-repeat center center;
    background-size: 50% 50%;
}

.button_icon_back:hover {
    background: url("/vendors/wovo/icons/common/icon_navigation_back_active.svg") no-repeat center center !important;
    background-size: 50% 50%;
}

.button_icon_add {
    position: relative;
    width: 40px !important;
    height: 40px !important;
    background: url("/vendors/wovo/icons/common/icon_navigation_back.svg") no-repeat center center;
    background-size: 50% 50%;
}

.button_icon_add:hover {
    background: url("/vendors/wovo/icons/common/icon_navigation_back_active.svg") no-repeat center center !important;
    background-size: 50% 50%;
}

.input_search_icon {
    position: relative;
    width: 40px !important;
    height: 40px !important;
    background: url("/vendors/wovo/icons/common/icon_filter_search.svg") no-repeat center center;
    background-size: 50% 50%;
}

.info_round_icon {
    position: relative;
    width: 18px !important;
    height: 40px !important;
    background: url("/vendors/wovo/icons/common/icon_round_info.svg") no-repeat center center;
}

.info_round_icon_switch {
    position: relative;
    width: 18px !important;
    height: 40px !important;
    background: url("/vendors/wovo/icons/common/icon_round_info.svg") no-repeat right right;
}

.info_round_icon_error {
    position: relative;
    width: 18px !important;
    height: 40px !important;
    background: url("/vendors/wovo/icons/common/icon_round_info_error.svg") no-repeat center center;
}

.form_horizontal_switch_border {
    border-top: 1px solid #EDF4F9;
}

.input_container_label_no_padding {
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.25px;
    color: #8D8D8D;
    width: 100%;
    height: 40px !important;
}

.input_container_no_margin {
    position: relative;
    margin-top: 0;
}

.input_container_no_margin .form_container_label_content {
    line-height: 40px;
}


.form_horizontal_switch_info_icon {
    position: relative;
    width: 18px !important;
    height: 40px !important;
    background: url("/vendors/wovo/icons/common/icon_round_info.svg") no-repeat center center;
}

.form_horizontal_switch_info_icon_parent {
    position: relative;
    float: right;
    cursor: pointer;
}

.form_horizontal_switch_info_icon_left_parent {
    position: relative;
    float: left;
    cursor: pointer;
    margin-left: 6px;
    margin-top: -2px;
}

.form_horizontal_switch_info_switch_parent {
    position: relative;
    float: left;
}


.table_list_active_label {
    background-color: #4B5152 !important;
    padding: 4px 10px 4px 10px !important;
    border-radius: 30px !important;
    color: #FFFFFF !important;
    text-align: center;
    margin-right: 32px;
}

.table_list_inactive_label {
    background-color: #F35F5F !important;
    padding: 4px 10px 4px 10px !important;
    border-radius: 30px !important;
    color: #FFFFFF !important;
    text-align: center;
    margin-right: 32px;
}

.label_input_with_icon_container_input {
    position: relative;
    width: calc(100% - 48px);
    float: left;
}

.label_input_with_icon_container_input_with_invite_page {
    position: relative;
    width: 32.125em;
    height: 2.375em;
    padding-top: 10px;
}

.survey_invite_single_and_multiple_response_text_style {
    font-weight: 400 !important;
    letter-spacing: -0.6px !important;
    font-size: 16px !important;
}

.survey_invite_single_and_multiple_response_text_style_bold {
    font-weight: 700 !important;
    letter-spacing: -0.6px !important;
    font-size: 16px !important;
}

.label_input_with_icon_container_input_title_payslip {
    position: relative;
    width: calc(100% - 48px);
    float: left;
    padding-bottom: 0;
}

.label_input_with_icon_container_icon {
    position: relative;
    width: 48px;
    float: left;
    margin-top: 8px;
}

.label_input_with_icon_container_icon_for_title_payslip {
    position: relative;
    width: 48px;
    float: left;
}

.label_input_with_icon_container_icon_for_title_invite_page {
    position: relative;
    padding-left: 32.125em;
}

.draft_mode_footer_note{
    font-weight: 500;
    font-size: 14px;
    line-height: 16.94px;
    letter-spacing: -0.3px;
    color: #242424;
    width: 890px;
    height: 51px;
    padding: 20px;
}

.closed_mode_footer_note{
    position: absolute;
    top: 50px;
    left: 200px;
    align-items: flex-start;
}

.closed_mode_first_section_text{
    width: 65px;
    height: 21px;
    padding: 2px 5px 2px 5px;
    background-color: #EFF1F1;
}

.closed_mode_first_section_text_inner_text{
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
    letter-spacing: -0.5px;
    text-align: center;
    color: #808080;
}

.closed_mode_first_section_text_inner_text_one span{
    font-weight: 600 !important;
    font-size: 14px;
    line-height: 16.94px;
    letter-spacing: -0.5px;
    text-align: center;
    color: #808080;
}

.closed_mode_first_section_text_inner_text_two{
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 14.52px;
    letter-spacing: -0.3px;
    width: 829.93px;
    height: 15px;
    color: #242424;
    padding-top: 15px;
}

.chips_list_box_container {
    position: relative;
    width: 100%;
    height: auto;
    padding: 9px 16px 9px 16px;
    border: 1px solid #D7D8D9;
    border-radius: 4px;
}

.chips_list_box_container .MuiSvgIcon-root {
    color: #FFFFFF !important;
    transform: scale(0.8);
    cursor: pointer;
}

.outline_dropdown_disabled_header_text {
    color: #CED1D1 !important;
}

.outline_dropdown .Mui-disabled {
    color: #A5A8A9 !important;
}

.outline_dropdown .MuiInputBase-formControl {
    background-color: #FFFFFF !important;
}

.outline_dropdown .MuiFormControl-marginNormal {
    margin-top: 0 !important;
}

.warning_popup_content {
    position: relative;
    min-height: 100px;
    min-width: 480px;
    width: 100%;
    height: auto;
}

.warning_popup_content_icon_container {
    position: relative;
    width: 100%;
    height: 100%;
    float: left;
}

.warning_popup_icon_text {
    position: absolute;
    float: right;
    width: calc(100% - 52px);
    height: 100%;
    right: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
}

.warning_popup_icon_text_warning {
    padding-right: 6px;
    font-weight: 600;
}

.warning_popup_icon_text_action {
    padding-right: 6px;
    font-weight: 500;
    padding-left: 6px;
}

.app_header_left_container {
    position: relative;
    width: auto;
    height: 100%;
    float: left;
    display: flex;
    align-items: center;
}

.app_header_right_container {
    position: absolute;
    width: auto;
    height: 100%;
    right: 0;
    float: right;
    display: flex;
    align-items: center;
    padding-right: 1.4rem;
}

.header_horizontal_divider {
    position: relative;
    height: 40px;
    width: 0;
    border: 1px solid #9DA3A3;
    float: left;
    margin-right: 10px;
    margin-left: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.header_custom_right_container {
    float: right;
    position: relative;
    height: 100%;
    width: auto;
    align-items: center;
    display: flex;
}
