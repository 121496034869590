
.wovo_icon {
  display: inline-block;
  height: 24px;
  width: 24px;
  margin-right: 6px;
}

.wovo_span_label {

  position: absolute;
  padding-top: 2px;

}

.wovo_icon_dashboard {
  background: url("/vendors/wovo/side_menu/dashboard.svg") no-repeat center center;
  background-size: contain;
}

.wovo_icon_dashboard_active{
  background: url("/vendors/wovo/side_menu/dashboard_active.svg") no-repeat center center !important;
  background-size: contain;
}

.wovo_icon_wc {
  background: url("/vendors/wovo/side_menu/participants.svg") no-repeat center center;
  background-size: contain;
}

.wovo_icon_wc_active{
  background: url("/vendors/wovo/side_menu/participants_active.svg") no-repeat center center !important;
  background-size: contain;
}


.wovo_icon_connect {
  background: url("/vendors/wovo/side_menu/connect.svg") no-repeat center center;
  background-size: contain;
}

.wovo_icon_connect_active{
  background: url("/vendors/wovo/side_menu/connect_active.svg") no-repeat center center;
  background-size: contain;
}


.wovo_icon_survey {
  background: url("/vendors/wovo/side_menu/survey.svg") no-repeat center center;
  background-size: contain;
}

.wovo_icon_survey_active {
  background: url("/vendors/wovo/side_menu/survey_active.svg") no-repeat center center;
  background-size: contain;
}


.wovo_icon_elearning {
  background: url("/vendors/wovo/side_menu/elearning.svg") no-repeat center center;
  background-size: contain;
}

.wovo_icon_elearning_active {
  background: url("/vendors/wovo/side_menu/elearning_active.svg") no-repeat center center;
  background-size: contain;
}

.wovo_icon_company {
  background: url("/vendors/wovo/side_menu/company.svg") no-repeat center center;
  background-size: contain;
}

.wovo_icon_company_active {
  background: url("/vendors/wovo/side_menu/company_active.svg") no-repeat center center;
  background-size: contain;
}

.wovo_icon_settings {
  background: url("/vendors/wovo/side_menu/settings.svg") no-repeat center center;
  background-size: contain;
}

.wovo_icon_settings_active {
  background: url("/vendors/wovo/side_menu/settings_active.svg") no-repeat center center;
  background-size: contain;
}

.wovo_icon_participants {
  background: url("/vendors/wovo/side_menu/participants.svg") no-repeat center center;
  background-size: contain;
}

.wovo_icon_participants_active {
  background: url("/vendors/wovo/side_menu/participants_active.svg") no-repeat center center;
  background-size: contain;
}

.wovo_icon_sync {
  background: url("/vendors/wovo/side_menu/sync.svg") no-repeat center center;
  background-size: contain;
}

.wovo_icon_sync_active {
  background: url("/vendors/wovo/side_menu/sync_active.svg") no-repeat center center;
  background-size: contain;
}

.wovo_icon_post_raw_data {
  background: url("/vendors/wovo/side_menu/rawdata.svg") no-repeat center center;
  background-size: contain;
}

.wovo_icon_post_raw_data_active {
  background: url("/vendors/wovo/side_menu/rawdata_active.svg") no-repeat center center;
  background-size: contain;
}

