/**
 * FixedDataTable v1.0.2 
 *
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 */

/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTable
 *
 */

/**
 * Table.
 */
.public_fixedDataTable_main {
  border-color: #d3d3d3;
}

.public_fixedDataTable_header,
.public_fixedDataTable_hasBottomBorder {
  border-color: #d3d3d3;
}

.public_fixedDataTable_header .public_fixedDataTableCell_main {
  /*font-weight: bold;*/
}

.public_fixedDataTable_header,
.public_fixedDataTable_scrollbarSpacer,
.public_fixedDataTable_header .public_fixedDataTableCell_main {
  background-color: #2196F3 !important;
  background-image: linear-gradient(#2196F3, #2196F3) !important;
  color: #FFFFEF !important;
}

.public_fixedDataTable_scrollbarSpacer {
  position: absolute;
  z-index: 99;
  top: 0;
}

.public_fixedDataTable_footer .public_fixedDataTableCell_main {
  background-color: #f6f7f8;
  border-color: #d3d3d3;
}

.public_fixedDataTable_topShadow {
  background-image: linear-gradient(180deg, rgba(0,0,0,0.1), rgba(0,0,0,0));
}

.public_fixedDataTable_bottomShadow {
  background-image: linear-gradient(0deg, rgba(0,0,0,0.1), rgba(0,0,0,0));
}

.public_fixedDataTable_horizontalScrollbar .public_Scrollbar_mainHorizontal {
  background-color: #fff;
}
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableCell
 */

/**
 * Table cell.
 */
.public_fixedDataTableCell_main {
  background-color: #fff;
  border-color: #d3d3d3;
}

.public_fixedDataTableCell_highlighted {
  background-color: #f4f4f4;
}

.public_fixedDataTableCell_cellContent {
  padding: 8px;
}

.public_fixedDataTableCell_columnResizerKnob {
  background-color: #0284ff;
}
.public_fixedDataTableCell_hasReorderHandle .public_fixedDataTableCell_cellContent {
  margin-left: 12px;
}

.fixedDataTable_isRTL .public_fixedDataTableCell_hasReorderHandle .public_fixedDataTableCell_cellContent {
  margin-left: auto;
  margin-right: 12px;
}
/**
 * Column reorder goodies.
 */
.fixedDataTableCellLayout_columnReorderContainer {
  border-color: #0284ff;
  background-color: rgba(0,0,0,0.1);
  width: 12px;
  margin-right: -12px;
  float: left;
  cursor: move;
}

.fixedDataTable_isRTL .fixedDataTableCellLayout_columnReorderContainer {
  margin-right: auto;
  margin-left: -12px;
  float: right;
}

.fixedDataTableCellLayout_columnReorderContainer:after {
	content: '::';
	position: absolute;
	top: 50%;
	left: 1px;
	transform: translateY(-50%);
}

.fixedDataTable_isRTL .fixedDataTableCellLayout_columnReorderContainer:after {
  left: auto;
  right: 1px;
}
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableColumnResizerLine
 *
 */

/**
 * Column resizer line.
 */
.public_fixedDataTableColumnResizerLine_main {
  border-color: #0284ff;
}
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule fixedDataTableRow
 */

/**
 * Table row.
 */
.public_fixedDataTableRow_main {
  background-color: #fff;
}

.public_fixedDataTableRow_highlighted,
.public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
  background-color: #f6f7f8;
}

.public_fixedDataTableRow_fixedColumnsDivider {
  border-color: #d3d3d3;
}

.public_fixedDataTableRow_columnsShadow {
  background-image: linear-gradient(90deg, rgba(0,0,0,0.1), rgba(0,0,0,0));
}

.fixedDataTable_isRTL .public_fixedDataTableRow_columnsShadow {
  background-image: linear-gradient(270deg, rgba(0,0,0,0.1), rgba(0,0,0,0));
}

.public_fixedDataTableRow_columnsRightShadow {
  transform: rotate(180deg);
}
/**
 * Copyright Schrodinger, LLC
 * All rights reserved.
 *
 * This source code is licensed under the BSD-style license found in the
 * LICENSE file in the root directory of this source tree. An additional grant
 * of patent rights can be found in the PATENTS file in the same directory.
 *
 * @providesModule Scrollbar
 *
 */

/**
 * Scrollbars.
 */

/* Touching the scroll-track directly makes the scroll-track bolder */
.public_Scrollbar_main.public_Scrollbar_mainActive,
.public_Scrollbar_main {
  background-color: #fff;
  border-left: 1px solid #d3d3d3;
}

.fixedDataTable_isRTL .public_Scrollbar_main.public_Scrollbar_mainActive,
.fixedDataTable_isRTL .public_Scrollbar_main {
  border-right: 1px solid #d3d3d3;
  border-left-width: 0;
}

.public_Scrollbar_mainOpaque,
.public_Scrollbar_mainOpaque.public_Scrollbar_mainActive,
.public_Scrollbar_mainOpaque:hover {
  background-color: #fff;
}

.public_Scrollbar_face:after {
  background-color: #c2c2c2;
}

.public_Scrollbar_main:hover .public_Scrollbar_face:after,
.public_Scrollbar_mainActive .public_Scrollbar_face:after,
.public_Scrollbar_faceActive:after {
  background-color: #7d7d7d;
}

.public_fixedDataTable_header .public_fixedDataTableCell_main {
    font-weight: 400;
   display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  font-size: 0.8rem;
}
.public_fixedDataTableCell_cellContent {
    padding: 8px;
}