/*.app-main-content {
    background-color: white;
}*/

.sv_header{
    background-color: white !important;
}

.languageDropDown {
    position: absolute !important;
    //top: 14px !important;
    right: 20px !important;
    z-index: 9 !important;
} 

.sv_main {
    font-size: 1rem !important;
    font-family: "Roboto", sans-serif, "Helvetica", "Arial" !important;
}

.sv_main .sv_container .sv_header h3 span {
    //color: white !important;
    color: #42a1ff !important;
    font-weight: bold !important;
}

.sv_main .sv_header__text {
    margin-top: 12px !important;
    margin-left: -25px !important;
}

.sv_main.sv_default_css .sv_body {
    padding-top: 75px !important;
    border-top: none !important;
}

.sv_main .sv_container .sv_body .sv_p_root > .sv_row {
    margin-bottom: 30px !important;
    border-bottom: none !important;
    font-size: 1.25rem !important;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_other input, .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_dropdown_control, .sv_main .sv_container .sv_body .sv_p_root .sv_q input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]), .sv_main .sv_container .sv_body .sv_p_root .sv_q select {
    background: transparent !important;
    border: none !important;
    border-bottom: 1px solid #000000 !important;
}

.sv_main .sv_container .sv_body .sv_nav .sv_start_btn, .sv_main .sv_container .sv_body .sv_nav .sv_next_btn, .sv_main .sv_container .sv_body .sv_nav .sv_complete_btn{
   
        margin-right: 5px !important;
        background-color:green ;
        /*box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important;*/
        //border-radius: 5px !important; 
        /*padding: .375rem .75rem!important;*/
        padding: .6rem 1.75rem .6rem 1.75rem !important;
        line-height: 1.2!important;
        min-width: 10px!important;
        border: transparent;

        min-height: 10px!important;
        font-size: .875rem!important;
        font-weight: 400!important;
        text-transform: capitalize!important;
        border-radius: .125rem!important;
}

.sv_main .sv_container .sv_body .sv_nav .sv_start_btn, .sv_main .sv_container .sv_body .sv_nav .sv_next_btn{
    padding: .6rem 1.75rem .6rem 1.75rem !important;
}

.sv_main .sv_container .sv_body .sv_nav .sv_complete_btn{
    padding: .6rem .8rem!important;
}

.sv_main .sv_container .sv_body .sv_nav .sv_prev_btn {
    background-color:#ff9800!important;
    padding: .6rem .8rem!important;

    line-height: 1.2!important;
    min-width: 10px!important;
    border: transparent;

    min-height: 10px!important;
    font-size: .875rem!important;
    font-weight: 400!important;
    text-transform: capitalize!important;
    border-radius: .125rem!important;

}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_title {
    font-weight: normal !important;
    font-size: 1em !important;
    margin: 0.5em 0 !important;
}

.sv_main .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
    border: none !important;
    background-color:white !important;
    color: #ed5565 !important;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_erbox:not([style*="display: none"]):not([style*="display:none"]) {
    
    margin: 0em 0 !important;
    padding: 1em !important;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_min_text {
    font-size: 10px !important;
}

.sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_max_text {
    font-size: 10px !important;
}

.jss110 {
    height: auto;
    display: flex;
    padding: 4px;
}

.MuiInputBase-input {
    font: inherit;
    color: currentColor;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 15%;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    border-bottom: 1px solid #ced4da;
}

.MuiInputBase-root {
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    display: inline-flex;
    position: relative;
    font-size: 1rem;
    box-sizing: border-box;
    align-items: center;
    font-family: "Roboto", sans-serif, "Helvetica", "Arial" !important;
    font-weight: 400;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
}

.sv_main .sv_container .sv_body .sv_p_root fieldset.sv_qcbc {
    line-height: 3em;
    padding-top: 0.8em;
}

.sv_main input[type="button"], .sv_main button {
    font-weight: 400!important;
    line-height: 1.2!important;
}