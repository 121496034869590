
.surveyQuestionEmojiTextContainerFull {
    width: 100%;
    float: left;
}

.surveyQuestionEmojiTextContainerEmoji {
    width: calc(100% - 42px);
    float: left;
}

.surveyQuestionEmojiContainer {
    display: block;
    height: auto;
    width: 42px;
    float: left;
    margin-top: 18px;
}

.surveyQuestionEmoji {
    display: block;
    height: 30px;
    width: 30px;
    margin-right: 12px;
}

.survey_question_emoji_very_positive {
    background: url("/vendors/wovo/survey_emojis/very_positive.png") no-repeat center center;
    background-size: contain;
}

.survey_question_emoji_positive {
    background: url("/vendors/wovo/survey_emojis/positive.png") no-repeat center center;
    background-size: contain;
}

.survey_question_emoji_neutral {
    background: url("/vendors/wovo/survey_emojis/neutral.png") no-repeat center center;
    background-size: contain;
}

.survey_question_emoji_negative {
    background: url("/vendors/wovo/survey_emojis/negative.png") no-repeat center center;
    background-size: contain;
}

.survey_question_emoji_very_negative {
    background: url("/vendors/wovo/survey_emojis/very_negative.png") no-repeat center center;
    background-size: contain;
}

.survey_question_emoji_skip {
    background: url("/vendors/wovo/survey_emojis/skip.png") no-repeat center center;
    background-size: contain;
}

.survey_question_back_emoji_label{
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: #8D8D8D;
    margin-top: 15%;
}

.survey_question_back_emoji_label_question{
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: #8D8D8D;
    margin-top: 13.5%;
    padding-top: 6px;
}

.survey_question_back_emoji{
    position: relative;
    margin-top: 5%;
}

.survey_question_back_emoji_parent{
    position: relative;
}

.survey_question_back_emoji_parent_rating_score{
    position: relative;
    max-height: 36px;
}

.surveyDisplayQuestionEmoji {
    display: block;
    height: 30px;
    width: 30px;
    margin-right: 12px;
    margin-top: 4px;
    float: left;
}

.surveyDisplayQuestionRadio {
    float: left;
}

.surveyEmojiQuestionOptionLabel {
    font-size : 1rem;
    font-weight : 400;
    float: left;
    position: relative;
    margin-top: 8px;
}

.surveyPieChartLegendLabel{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #4F4F4F;
}

.survey_emoji_info_icon {
    background: url("/vendors/wovo/informations.svg") no-repeat center center;
    background-size:contain;
    width: 20px;
    height: 20px;
    float: right;
    position: relative;
    margin-left: 12px;
    margin-top: -2px;
    cursor: pointer;
}

.survey_emoji_info_icon_container {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4px;
    color: #FFFFFF;
    background: #292D2D;
    padding: 10px;
    width: 400px;
}

/*.survey_emoji_info_icon_root_container{
    max-width: 40% !important;
}*/
