
.contact_settings_theme_box_card {
    position: relative;
    height: 100%;
    margin-bottom: 10px !important;
    padding: 50px 50px 0 50px !important;
}


.contact_settings_container_row_header {
    position: relative;
    width: 100%;
    height: auto;
    font-style: normal;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #222829;
}

.contact_settings_info_content {
    position: relative;
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
    overflow: hidden;
    width: 100%;
    height: auto;
    max-height: calc(100% - 88px);
    overflow-y: scroll;
}

.contact_settings_apply_button_content {
    position: relative;
    height: 88px;
    width: 100%;
    float: left;
}

.contact_settings_element_bottom{
    margin-bottom: 10px !important;
}
