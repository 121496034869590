.surveyTranslationsUploadIcon {
    display: block;
    height: 36px;
    /*width: 36px;
    margin-left: auto;
    margin-right: auto;*/
    margin-top: 4px;
    background: url("/vendors/wovo/icons/upload.svg") no-repeat center center;

    background-size: 36px 36px;
    width: auto;
    min-width: 30rem;
    background-position-x: left;
}

.uploadIcon {
    background: url("/vendors/wovo/icon_upload.svg") no-repeat center center;
    background-size: contain
}

.downloadIcon {
    background: url("/vendors/wovo/icon_download.svg") no-repeat center center;
    background-size: contain
}

.survey_info_widget .ls_icons {
    display: block;
    width: 20px;
    height: 20px;
    background: #DADADA;
    background-size: 100% 100% !important;
}

.survey_info_widget .ls_icon_calender {
    background: url("/vendors/wovo/icons/survey_info/calender.svg") no-repeat center center;
}

.survey_info_widget .ls_icon_questions {
    background: url("/vendors/wovo/icons/survey_info/list.svg") no-repeat center center;
}

.survey_info_widget .ls_icon_languages {
    background: url("/vendors/wovo/icons/survey_info/globe.svg") no-repeat center center;
}

.survey_info_widget .ls_icon_surveys {
    background: url("/vendors/wovo/icons/survey_info/survey.svg") no-repeat center center;
}

.survey_info_widget .ls_icon_responses_device {
    background: url("/vendors/wovo/icons/survey_info/mobile_device.svg") no-repeat center center;
}

.survey_info_widget .ls_icon_categories {
    background: url("/vendors/wovo/icons/survey_info/categories.svg") no-repeat center center;
}

.survey_info_widget .ls_icon_companies {
    background: url("/vendors/wovo/icons/survey_info/companies.svg") no-repeat center center;
}

.survey_questionnaire_list .ls_icon_delete {
    background: url("/vendors/wovo/icons/survey_list/delete.svg") no-repeat center center;
}

.survey_questionnaire_list .ls_icon_assigned {
    background: url("/vendors/wovo/icons/survey_list/assigned_companies_icon.svg") no-repeat center center;
}

.survey_questionnaire_list .ls_icon_report {
    background: url("/vendors/wovo/icons/survey_list/view_report.svg") no-repeat center center;
}

.survey_questionnaire_list .ls_icons {
    width: 20px !important;
    height: 20px !important;
    position: absolute;
}

.survey_navigation_icon_back {
    position: absolute;
    width: 20px !important;
    height: 20px !important;
    padding: 18px !important;
    background: url("/vendors/wovo/icons/survey_list/navigation_back.svg") no-repeat center center;

}


.survey_navigation_icon_forward {
    position: absolute;
    width: 20px !important;
    height: 20px !important;
    padding: 18px !important;
    background: url("/vendors/wovo/icons/survey_list/navigation_forward.svg") no-repeat center center;
}

.iconPdf {
    background: url("/vendors/wovo/icon_pdf.png") no-repeat center center;
    background-size: contain
}

.quick_insight_graph_icon_container {
    position: relative;
    width: 35px !important;
    height: 35px !important;
    margin-right: 8px;
}

.quick_insight_graph_icon {
    position: relative;
    width: 35px !important;
    height: 35px !important;
    cursor: pointer;
}

.quick_insight_graph_icon_bar {
    background: url("/vendors/wovo/icons/quick_insight/icon_bar_chart.svg") no-repeat center center;
    background-size: contain
}

.quick_insight_graph_icon_bar_active {
    background: url("/vendors/wovo/icons/quick_insight/icon_bar_chart_active.svg") no-repeat center center;
    background-size: contain
}

.quick_insight_graph_icon_pie {
    background: url("/vendors/wovo/icons/quick_insight/icon_pie_chart.svg") no-repeat center center;
    background-size: contain;
}

.quick_insight_graph_icon_pie_active {
    background: url("/vendors/wovo/icons/quick_insight/icon_pie_chart_active.svg") no-repeat center center;
    background-size: contain;
}

.quick_insight_graph_icon_line {
    background: url("/vendors/wovo/icons/quick_insight/icon_line_chart.svg") no-repeat center center;
    background-size: contain;
}

.quick_insight_graph_icon_line_active {
    background: url("/vendors/wovo/icons/quick_insight/icon_line_chart_active.svg") no-repeat center center;
    background-size: contain;
}

.ls_button_icon {
    position: relative;
    width: 14px !important;
    height: 14px !important;
}

.ls_button_icon_plus_active {
    background: url("/vendors/wovo/icons/common/button_icon_plus_active.svg") no-repeat center center;
    background-size: 100% 100%;
}

.ls_button_icon_groups_active {
    background: url("/vendors/wovo/icons/common/button_icon_groups_active.svg") no-repeat center center;
    background-size: 120% 120%;
}

.ls_button_icon_import_active {
    background: url("/vendors/wovo/icons/common/button_icon_import_active.svg") no-repeat center center;
    background-size: 100% 100%;
}

.ls_button_icon_group_active {
    background: url("/vendors/wovo/icons/common/button_icon_group_active.svg") no-repeat center center;
    background-size: 100% 100%;
}

.ls_button_icon_download_active {
    background: url("/vendors/wovo/icons/common/button_icon_download_active.svg") no-repeat center center;
    background-size: 100% 100%;
}

.headerButton {
    border: 1px solid #B5B5B5 !important;
    color: $app_default_primary_color !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    letter-spacing: 0.5px !important;
    text-transform: none !important;
    align-items: flex-end !important;
}

.headerButton:hover {
    color: #ffffff !important;
    background-color: $app_default_primary_color !important;
    border: 1px solid $app_default_primary_color !important;
}

.button_icon_square {
    position: relative;
    width: 40px !important;
    height: 40px !important;
    background-color: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #DFDBDB !important;
    color: $app_default_primary_color !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    letter-spacing: 0.5px !important;
    text-transform: none !important;
    align-items: flex-end !important;
}

.button_icon_transparent {
    position: relative;
    width: 40px !important;
    height: 40px !important;
    background-color: transparent;
    cursor: pointer;
    color: $app_default_primary_color !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 17px !important;
    letter-spacing: 0.5px !important;
    text-transform: none !important;
    align-items: flex-end !important;
}

.button_icon_search {
    position: relative;
    width: 40px !important;
    height: 40px !important;
    background: url("/vendors/wovo/icons/common/icon_filter.svg") no-repeat center center;
    background-size: 50% 50%;
}

.button_icon_search:hover {
    background: url("/vendors/wovo/icons/common/icon_filter_active.svg") no-repeat center center !important;
    background-size: 50% 50%;
}

.button_icon_back {
    position: relative;
    width: 40px !important;
    height: 40px !important;
    background: url("/vendors/wovo/icons/common/icon_navigation_back.svg") no-repeat center center;
    background-size: 50% 50%;
    float: left;
    cursor: pointer;
}

.button_icon_back:hover {
    background: url("/vendors/wovo/icons/common/icon_navigation_back_active.svg") no-repeat center center !important;
    background-size: 50% 50%;
}

.input_search_icon {
    position: relative;
    width: 40px !important;
    height: 40px !important;
    background: url("/vendors/wovo/icons/common/icon_filter_search.svg") no-repeat center center;
    background-size: 50% 50%;
}

.info_round_icon {
    position: relative;
    width: 18px !important;
    height: 40px !important;
    background: url("/vendors/wovo/icons/common/icon_round_info.svg") no-repeat center center;
}

.info_round_checkbox {
    position: relative;
    width: 28px !important;
    height: 28px !important;
    background: url("/vendors/wovo/icons/common/icon_round_checkbox.svg") no-repeat center center;
}

.info_round_checkbox_checked {
    position: relative;
    width: 28px !important;
    height: 28px !important;
    background: url("/vendors/wovo/icons/common/icon_round_checkbox_checked.svg") no-repeat center center;
}

.info_round_icon_error {
    position: relative;
    width: 18px !important;
    height: 40px !important;
    background: url("/vendors/wovo/icons/common/icon_round_info_error.svg") no-repeat center center;
}


.menu_popover_item_icon_delete {
    background: url("/vendors/wovo/icons/common/icon_delete.svg") no-repeat center center;
    background-size: contain
}

.menu_popover_item_icon_deactivate {
    background: url("/vendors/wovo/icons/common/icon_deactivate.svg") no-repeat center center;
    background-size: contain
}

.menu_popover_item_icon_reactivate {
    background: url("/vendors/wovo/icons/common/icon_reactivate.svg") no-repeat center center;
    background-size: contain
}

.menu_popover_item_icon_export {
    background: url("/vendors/wovo/icons/common/icon_export.svg") no-repeat center center;
    background-size: contain
}

.menu_popover_item_icon_add_groups {
    background: url("/vendors/wovo/icons/common/icon_add_groups.svg") no-repeat center center;
    background-size: contain
}

.filter_icon_alert_info {
    position: absolute;
    width: 18px;
    height: 18px;
    background: url("/vendors/wovo/icons/common/icon_info_circle.svg") no-repeat center center !important;
    background-size: 100% 100%;
}

.filter_icon_alert_settings {
    position: relative;
    width: 18px;
    height: 18px;
    background: url("/vendors/wovo/icons/common/icon_settings_circle.svg") no-repeat center center !important;
    background-size: 100% 100%;
}

.ls_upload_file_img {
    position: relative;
    width: 100%;
    height: 100%;
    background: url("/vendors/wovo/icons/common/icon_upload_file.svg") no-repeat center center !important;
    background-size: 100% 100%;
}

.payslip_upload_file_img {
    position: relative;
    width: 100%;
    height: 100%;
    background: url("/vendors/wovo/icons/common/icon_upload_file.svg") no-repeat center center !important;
    background-size: 100% 100%;
}

.filter_icon_info_title_icon {
    position: relative;
    width: 20px;
    height: 20px;
    float: left;
    margin-left: 12px;
    background: url("/vendors/wovo/icons/common/icon_info_circle.svg") no-repeat center center !important;
    background-size: contain;
}

.wc_slider_arrow_right {
    background: url("/vendors/wovo/icons/common/icon_navigation_forward_enabled.svg") no-repeat center center !important;
    background-size: contain;
}

.wc_slider_arrow_right_disabled {
    background: url("/vendors/wovo/icons/common/icon_navigation_forward_disabled.svg") no-repeat center center !important;
    background-size: contain;
}

.wc_slider_arrow_left {
    background: url("/vendors/wovo/icons/common/icon_navigation_back_enabled.svg") no-repeat center center !important;
    background-size: contain;
}

.wc_slider_arrow_left_disabled {
    background: url("/vendors/wovo/icons/common/icon_navigation_back_disabled.svg") no-repeat center center !important;
    background-size: contain;
}

.wc_deactivate_popup_contacts_icon {
    position: relative;
    width: 36px;
    height: 36px;
    float: left;
    background: url("/vendors/wovo/icons/common/icon_round_info_error.svg") no-repeat center center;
    background-size: contain;
}

.warning_popup_icon {
    position: relative;
    width: 36px;
    height: 36px;
    float: left;
    background: url("/vendors/wovo/icons/common/icon_round_info_error.svg") no-repeat center center;
    background-size: contain;
}

.wc_reactivate_popup_contacts_icon {
    position: relative;
    width: 36px;
    height: 36px;
    float: left;
    background: url("/vendors/wovo/icons/common/icon_round_info_error.svg") no-repeat center center;
    background-size: contain;
}

.survey_setting_info_icon {
    background: url("/vendors/wovo/informations.svg") no-repeat center center;
    background-size: contain;
    width: 20px;
    height: 20px;
    cursor: default;
}

.survey_setting_cancel_button_icon {
    background: url("/vendors/wovo/cancelButton_icon.svg") no-repeat center center;
    background-size: contain;
    width: 20px;
    height: 20px;
}

.survey_table_row_sorted_indicator {
    background: url("/vendors/wovo/rowSortIndicator.svg") no-repeat center center;
    background-size: contain;
    height: 10px;
    width: 10px;
    display: inline-block;
}

.survey_table_column_sorted_indicator {
    background: url("/vendors/wovo/columnSortIndicator.svg") no-repeat center center;
    background-size: contain;
    height: 10px;
    width: 10px;
    display: inline-block;
}

.survey_sort_popup_icon_active {
    background: url("/vendors/wovo/sortingIconActive.svg") no-repeat center center;
    background-size: contain;
}

.survey_sort_popup_icon {
    background: url("/vendors/wovo/sortingIcon.svg") no-repeat center center;
    background-size: contain;
}

.survey_sort_popup_row_indicator_icon {
    background: url("/vendors/wovo/sortRow.svg") no-repeat center center;
    background-size: contain;
    height: 32px;
    width: 32px;
}

.survey_sort_popup_column_indicator_icon {
    background: url("/vendors/wovo/sortColumn.svg") no-repeat center center;
    background-size: contain;
    height: 32px;
    width: 32px;
}

.survey_sort_popup_delete_icon {
    background: url("/vendors/wovo/deleteIcon.svg") no-repeat center center;
    background-size: contain;
    height: 20px;
    width: 20px;
}

.dialog_cancel_button {
    background: url("/vendors/wovo/dialog_cancel_button.svg") no-repeat center center;
    background-size: contain;
    height: 14.37px;
    width: 14px;
    cursor: pointer;
}

.dialog_survey_button {
    background: url("/vendors/wovo/dialog_cancel_button.svg") no-repeat center center;
    background-size: contain;
    height: 14.37px;
    width: 14px;
    cursor: pointer;
}

.dialog_close_survey_button {
    background: url("/vendors/wovo/dialog_survey_invite_close_button.svg") no-repeat center center;
    background-size: contain;
    height: 14.37px;
    width: 14px;
    cursor: pointer;
}

.payslip_preview_dialog_warning_icon {
    position: relative;
    width: 36px;
    height: 36px;
    float: left;
    background: url("/vendors/wovo/icons/common/icon_round_info_error.svg") no-repeat center center;
    background-size: contain;
}


.wc_import_menu_indicator{
    background: url("/vendors/wovo/menuIndicator.svg") no-repeat center center;
    background-size: contain;
}
.survey_questionnaire_list .ls_icon_assigned {
    background: url("/vendors/wovo/icons/survey_list/assigned_companies_icon.svg") no-repeat center center;
}

.ls_icon_qr {
    background: url("/vendors/wovo/icons/survey_list/qr_code_icon.svg") no-repeat center center;
    height: 20px;
    width: 20px;
}

.ls_icon_copy {
    background: url("/vendors/wovo/icons/survey_list/copy_icon.svg") no-repeat center center;
    height: 20px;
    width: 20px;
}
.ls_icon_exclamation {
    background: url("/vendors/wovo/icons/survey_list/exclamation_icon.svg") no-repeat center center;
    height: 18px;
    width: 18px;
}

.ls_icon_red_dot {
    background: url("/vendors/wovo/icons/survey_list/red_dot.svg") no-repeat center center;
    height: 20px;
    width: 20px;
    position: absolute;
}
.wc_import_menu_indicator{
    background: url("/vendors/wovo/menuIndicator.svg") no-repeat center center;
    background-size: contain;
}

.wc_import_menu_button:hover .wc_import_menu_indicator{
    background: url("/vendors/wovo/menuIndicator_white.svg") no-repeat center center;   
}

.wc_import_menu_icon_active {
    background: url("/vendors/wovo/icons/common/button_icon_import_non_active.svg") no-repeat center center;
    background-size: 100% 100%;
}

.wc_import_menu_button:hover .ls_button_icon_import_active{
    background: url("/vendors/wovo/icons/common/button_icon_import_non_active.svg") no-repeat center center;
    background-size: 100% 100%;  
}

.wc_upload_file_img {
    position: relative;
    width: 100%;
    height: 100%;
    background: url("/vendors/wovo/icons/common/file_upload_img.svg") no-repeat center center !important;
    background-size: 100% 100%;
}

.reset_registration_download_template_button:hover .ls_button_icon_download_active {
    background: url("/vendors/wovo/icons/common/button_icon_plus_non_active.svg") no-repeat center center;
    background-size: 100% 100%;
}

.maintenance_banner_icon {
    background: url("/vendors/wovo/icons/common/bannerIcon.svg") no-repeat center center;
    width: 32px;
    height: 32px;
}

.maintenance_banner_cancel_icon {
    background: url("/vendors/wovo/icons/common/Cross.svg") no-repeat center center;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.maintenance_page_wovo_icon {
    background: url("/vendors/wovo/icons/common/wovo_maintenance_icon.svg") no-repeat center center;
    width: 64px;
    height: 64px;
    flex-shrink: 0;
}