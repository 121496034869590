
.clients_content_full_container {
  position: relative;
  float: right;
  width: 100%;
}

.clients_content_full_sub_container {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: -20px;
  padding: 20px !important;
}

.client_active_container{
  position: relative;
}

.clients_theme_box_card {
  position: relative;
  height: 100%;
  margin-bottom: 10px !important;
  padding: 50px 50px 0 50px !important;
}

.client_wovo_client_container{
  position: relative;
  float: right;
  width: 100%;
}

.client_is_active_container{
  position: relative;
  float: left;
  width: 25%;
}

.client_is_active_container .form_container_input_parent_right{
  float: left;
  left: 9% !important;
  top: -40px !important;
}

.client_basic_info_container{
  position: relative;
  float: right;
  width: calc(99% - 220px);
  margin-top: -2%;
}

.client_relations_container{
  position: relative;
  float: right;
  width: 100%;
  padding-bottom: 50px;
}

.clients_view .MultiSelectDropDown{
  min-height: 64px !important;
}

.clients_view .MultiSelectDropDown .form_container_label_content{
  line-height: 85px !important;
}


.clients_view .form_container_root_next_multi_select_dropdown{
  margin-top: -56px !important;
}

.clients_view .industry_dropdown{
  margin-top: -4px !important;
}

.client_relations_sub_container,
.client_basic_info_sub_container{
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 0;
}

.clients_container_row_header {
  position: relative;
  width: 100%;
  height: auto;
  font-style: normal;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #222829;
}
