
.ls_profile_image_container {
    position: relative;
    width: 220px;
    height: auto;
}

.ls_profile_image_sub_container {
    position: relative;
    width: 200px;
    height: 200px;
    background-color: #FAFAFB !important;
}

.ls_profile_image_container_img {
    position: relative;
    width: 200px;
    height: 200px;
    line-height: 200px;
    background-color: #4EB0FE !important;
    display: flex;
    justify-content: center;
    border-radius: 4px !important;
    overflow: hidden;
}

.ls_profile_image_container_img img {
    max-width: 100%;
    max-height: 100%;
    display: inline-block;
    margin: 0 auto;
    vertical-align: middle;
    align-content: center;
    object-fit: cover;
}

.ls_profile_img_title {
    position: relative;
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.25px;
    color: #4F4F4F !important;
    padding-top: 24px;
}

.ls_profile_img {
    position: relative;
    width: 75px;
    height: 75px;
    background: url("/vendors/wovo/icons/common/client_img_placeholder.svg") no-repeat center center !important;
    background-size: 100% 100%;
    margin-left: 64px;
    margin-top: 16px;
}

.ls_profile_img_desc {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #4F4F4F;
    margin-top: 16px;
}

.ls_profile_img_bottom_info {
    position: relative;
    width: 200px;
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;
    color: #8D8D8D;
}

.ls_profile_img_color_star {
    color: red !important;
    padding-right: 6px;
}

.ls_profile_image_container .dropzone-file-btn {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 !important;
    border: 0;
}

